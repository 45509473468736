<script>
import form from './form'

export default {
  extends: form,

  created() {
    window.isLoading(true)

    this.$apiMethods.stores.get(this.$route.params.id)
      .then(store => {
        this.form = store
        this.coordinates = store.lon + ', ' + store.lat
      })
      .finally(() => { window.isLoading(false) })
  },

  methods: {
    onSubmit() {
      return this.$apiMethods.stores.update(this.form.id, { ...this.form, is_external: true, ...this.getCoords })
        .then(() => {
          this.successSubmit({ message: 'Склад успешно изменен', routeName: 'pc-stores' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>

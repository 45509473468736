<script>
import form from '@/views/RccStores/form'

export default {
  extends: form,

  methods: {
    handleCancelClick() {
      this.$router.push({ name: 'pc-stores' })
    }
  }
}
</script>
